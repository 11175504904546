import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";


const Header = () => {
  const [activePath, setActivePath] = useState("/");
 const location = useLocation();
  const navigate = useNavigate();

	  let changeRoute = (e, path) => {
      e.preventDefault();
    //   isShowLoader(true);
      if (path !== null && path !== "") {
        // setcurrentPathName(path);
		  $(".navicon").removeClass("navicon--active");
          $(".toggle").removeClass("toggle--active");
		  setActivePath(path);
        navigate(path);
     

      }
    };


//   useEffect(() => {
//     $(".navicon").on("click", function (e) {
//       e.preventDefault();
//       $(this).toggleClass("navicon--active");
//       $(".toggle").toggleClass("toggle--active");
//     });

//     // Cleanup function to remove the event listener when the component unmounts
//     return () => {
//       $(".navicon").off("click");
//     };
//   }, []);

 useEffect(() => {
   // Set the active path based on the current location
   setActivePath(location.pathname); // Update the active path when the location changes

   $(".navicon").on("click", function (e) {
     e.preventDefault();
     $(this).toggleClass("navicon--active");
     $(".toggle").toggleClass("toggle--active");
   });

   // Cleanup function to remove the event listener when the component unmounts
   return () => {
     $(".navicon").off("click");
   };
 }, [location.pathname]); 



  return (
    <div className="header">
      <div className="container">
        <div className="heder-top">
          <div
            className="logo wow fadeInDownBig animated animated"
            data-wow-delay="0.4s"
          >
            <h1>
              <a href="" onClick={(e) => changeRoute(e, "/")}>
                Groove Design Studio
              </a>
            </h1>
          </div>
          <div className="nav-icon">
            <a href="" className="navicon"></a>
            <div className="toggle">
              <ul className="toggle-menu">
                <li>
                  <a
                    className={activePath === "/" ? "active" : ""}
                    href=""
                    onClick={(e) => changeRoute(e, "/")}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    className={activePath === "/about-us" ? "active" : ""}
                    href="/about-us"
                    onClick={(e) => changeRoute(e, "/about-us")}
                  >
                    About
                  </a>
                </li>

                <li>
                  <a
                    className={activePath === "/gallery" ? "active" : ""}
                    href="/gallery"
                    onClick={(e) => changeRoute(e, "/gallery")}
                  >
                    Gallery
                  </a>
                </li>

                <li>
                  <a
                    className={activePath === "/contact-us" ? "active" : ""}
                    href="/contact-us"
                    onClick={(e) => changeRoute(e, "/contact-us")}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
        <div
          className="banner-text wow fadeInLeft animated animated"
          data-wow-delay="0.4s"
        >
          <h3>We are a Creative Interior Design Studio</h3>
          <p>
            There is no one who loves pain itself, who seeks after it and wants
            to have it, simply because it is pain
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;

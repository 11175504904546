import React, { useEffect, useState } from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Header from "./partials/Header";
import Footer from "./partials/Footer";

import "./assets/css/animate.css";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/styles.css";
import "./assets/css/component.css";
import "./assets/css/lightbox.css";


//import file by lazy load
const Home = React.lazy(() => import("./views/Homepage/Home"));
const About = React.lazy(() => import("./views/About/About"));
const Contact = React.lazy(() => import("./views/Contact/Contactus"));
const Gallery = React.lazy(() => import("./views/Gallery/Gallery"));



function App() {
  let [siteInfo, setSiteInfo] = useState({});

  return (
    <div className="App" id="mainPage">
      <BrowserRouter basename="/">
        <Header />
        <div className="mainbodyarea">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <React.Suspense fallback={<></>}>
                  <Home />
                </React.Suspense>
              }
            />

            <Route
              exact
              path="/about-us"
              element={
                <React.Suspense fallback={<></>}>
                  <About />
                </React.Suspense>
              }
            />

            <Route
              exact
              path="/contact-us"
              element={
                <React.Suspense>
                  <Contact />
                </React.Suspense>
              }
            />

            <Route
              exact
              path="/gallery"
              element={
                <React.Suspense>
                  <Gallery />
                </React.Suspense>
              }
            />
          </Routes>
        </div>
        <div>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

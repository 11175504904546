import React from 'react'

const Footer = () => {
  return (
    <>
      <div
        className="footer-section wow fadeInDownBig animated animated"
        data-wow-delay="0.4s"
      >
        <div className="container">
          <div className="social-icons">
            <a href="#">
              <i className="icon"></i>
            </a>
            <a href="#">
              <i className="icon1"></i>
            </a>
            <a href="#">
              <i className="icon2"></i>
            </a>
            <a href="#">
              <i className="icon3"></i>
            </a>
          </div>
          <div className="copy">
            <p>
              &copy; {new Date().getFullYear()} Groove Design Studio . All rights
              reserved | Design by <a href="http://google.com">Google</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer